@import './../../assets/scss/app.scss';
.app{
    .main{
        .main-content{
            .content{
                h1,h2,h3,h4,h5,h6{
                    
                }
                a,p{
                }
            }
            .social-links{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding-top: 10px;
                .icon-link {
                    display: block;
                    width: 30px;
                    height: 30px;
                    margin-right: 2px;
                    margin-left: 2px;
                    margin-bottom: 4px;
                    border-radius: 4px;
                    background-color: rgba(255, 255, 255, 0.4);
                    transition: 0.4s;
                    padding: 6px;
                    path, g, line, rect, circle{
                        transition: 0.4s;
                    }
                    &:hover{
                        background-color: rgba(40, 44, 52, 0.5);
                        path, g, line, rect, circle{
                            &:not(.const){
                                fill: #fff !important;                               
                            }
                            &.const{
                                &.youtube{
                                    fill: #000 !important;
                                }
                            }
                        }
                        
                    }
                    img,svg{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        display: block;
                    }
                }
            }
        }
    }
}