// @import url('https://fonts.googleapis.com/css2?family=Khmer&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Metal&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Freehand&display=swap');
// @font-face{
//     src : url('./../fonts/Khmer/Khmer-Regular.ttf');
//     font-family: 'Khmer';
//     display : swap;
// }
@font-face{
    src : url('./../fonts/KhmerOS/KhmerOSFreehand.ttf');
    font-family: 'KhmerOSFreehand';
    display : swap;
}
$color-icon: #202028;
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html, body{
    line-height: 1.6em;
    font-size: 16px;
}
h1{

}
h2{
    font-size: 1.8em;
    line-height: 1.8em;
}
// .st0{fill:#2D2D87}
// .st1{fill:#BA2025}
// .st2{fill:#FFFFFF}
.app{
    &.lang-kh{
        * {
            font-family: 'KhmerOSFreehand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        }
        .t{
            line-height: 1.8em;
            font-size: 14px;
        }
        h2{
            &.t{
                font-size: 1.6em;
                line-height: 1.8em;
            }
        }
    }
    &.lang-en{
        * {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        }
    }
    .languages{
        position: fixed;
        right: 0;
        top: 0;
        padding: 20px;
        width: 100%;
        pointer-events: none;
        ul{
            display: flex;
            width: 100%;
            justify-content: flex-end;
            max-width: 1200px;
            margin: auto;
            li{
                list-style: none;
                margin-left: 6px;
                a{
                    width: 32px;
                    display: block;
                    height: 18px;
                    border-radius: 2px;
                    overflow: hidden;
                    padding: 1px;
                    pointer-events: all;
                    img,svg{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        display: block;
                        border-radius: 4px;
                        filter: grayscale(1);
                        transition: 0.4s;
                    }
                    &.active{
                        img{
                            filter: grayscale(0);
                        }
                    }
                    &:hover{
                        img{
                            filter: grayscale(0.3);
                        }
                    }
                }
            }
        }
    }
}
.app{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    text-align: center;
    background-color: #282c34;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &:before {
        content: "";
        display: block;
        // background: rgba(40, 44, 52, 0.5);
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
    }
    .main{
        display: table;
        width: 100%;
        height: 100%;
        .main-content{
            display: table-cell;
            vertical-align: middle;
            padding: 20px;
            .content{
                h1,h2,h3,h4,h5,h6{
                    
                }
                a,p{
                    color: white;
                }
            }
        }
    }
}